<template>
  <div :class="$style.root">
    <slot />
  </div>
</template>

<style module>
.root {
  margin-bottom: calc(var(--stroke--thin) * -1);
  overflow: hidden;
  padding-bottom: 0.3rem;
  padding-top: 0.5rem;

  border-bottom: var(--stroke--thin) solid var(--color--primary);
  border-top: var(--stroke--thin) solid var(--color--primary);

  transition: padding-left .075s ease-in-out, padding-right .075s ease-in-out;
}
</style>
