<template>
  <div
    v-if="downloads?.length"
    :class="$style.root"
  >
    <CoreTextComponent
      v-if="richText"
      :class="$style.richText"
      :string="richText"
    />

    <ul :class="$style.list">
      <li
        v-for="(file, index) in files"
        :class="$style.item"
      >
        <a :href="file.url" :class="$style.link" download target="_blank">
          <span :class="$style.label">{{ file.label }}</span> <span :class="$style.details">{{ file?.mime?.split('/')?.pop()?.toUpperCase() }}, {{ file?.humanSize }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    downloads: {
      type: Array,
    },
    richText: {
      type: String,
      required: false,
    }
  })

  const files = props?.downloads?.map(file => {
    const attributes = baseStrapiGetAttributes(file)
    return {
      ...attributes,
      label: file?.label,
      humanSize: attributes?.size ? `${(attributes?.size / 1000).toFixed(2)}MB` : null,
      url: baseStrapiGetAbsoluteFileUrl(attributes?.url, attributes?.provider)
    }
  })?.filter(Boolean)

</script>

<style module>
.root {
}

.richText {
  composes: font-size-small from global;
  color: var(--color--secondary);
  margin-bottom: var(--font-small--line-height);
}

.list {
  composes: reset-list from global;
}

.item:not(:first-child) {
  border-top: var(--stroke--default) solid var(--color--secondary);
  margin-top: calc(var(--padding--element--top) - var(--stroke--default));
  padding-top: var(--padding--element--top);
}

.link {
  composes: reset-link from global;
}

.details {
  color: var(--color--secondary);
}

.link:hover .label {
  text-decoration-line: underline;
}
</style>
